import React, { useState, useCallback } from "react"
import { useForm } from "react-hook-form"
import RECAPTCHA from "react-google-recaptcha"

import { Form, Button } from "react-bootstrap"

export default function ContactForm() {
  const [message, setMessage] = useState("")
  const { register, handleSubmit, formState, reset } = useForm()
  const [disableSubmit, setDisableSubmit] = useState(true)
    
  const submitForm = data => 
    
    // get RECAPTCHA token 
    //const token = await reRef.current.executeAsync();
    // reset ReCAPTCHA
    //reRef.current.reset();
    //console.log(token)

    fetch('https://ndys5lgun9.execute-api.us-west-2.amazonaws.com/production/teton-ridge-classic-contact', {
        headers: { "Content-Type": "application/json; charset=utf-8" },
        method: 'POST',
        body: JSON.stringify(data)
})
.then((response) => {
    if (response.status === 200) {
        console.log(JSON.stringify(data));
        setMessage('Message Sent!');
        reset();
    } else {
        console.log('network error')
    }
})        
setTimeout((data) => { 
    //alert(JSON.stringify(values, null, 2));
    // actions.resetForm();
    // actions.setSubmitting(false);
}, 400);


  return (
    <div>
      <Form onSubmit={handleSubmit(submitForm)} 
            id="contact-form"
            noValidate
            >
        <Form.Group>
          <Form.Control
            className="formField"
            type="text"
            name="yourName"
            placeholder="Name"
            ref={register}
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
          className="formField"
            type="email"
            name="email"
            placeholder="Email"
            ref={register}
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
          className="formField"
            type="text"
            name="phone"
            placeholder="Phone"
            ref={register}
          />
        </Form.Group>
        <Form.Label>
          <p className="messageTitle">
            Questions? Comments?
          </p>
        </Form.Label>
        <Form.Group>
          <Form.Control className="formField"
           as="textarea" name="message" ref={register} />
        </Form.Group>
        <div className="my-2">
          <RECAPTCHA 
            sitekey="6Lf8CtQZAAAAAKQuxV06f3WxTItzDQyV7LLR3APH"
            size="normal"
            ref={register}
            onChange={useCallback(() => setDisableSubmit(false))}
          />
        </div>
        <Button type="submit" variant="primary" form="contact-form" disabled={disableSubmit} className="submitButton trcButton">
          {formState.isSubmitted
            ? message
            : formState.isSubmitting
            ? "Sending..."
            : "Send"}
        </Button>
      </Form>
    </div>
  )
}
