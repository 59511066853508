import React from "react"
import { graphql } from "gatsby"
import Img from 'gatsby-image'

import { Container, Row, Col } from 'react-bootstrap'

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from "../components/PageTitle/PageTitle"
import ContactForm from "../components/ContactForm/ContactForm"
import Partners from "../components/PartnersSponsors/Partners"
import Sponsors from "../components/PartnersSponsors/Sponsors"

const Contact = ({ data }) => (
  <Layout>
    <SEO title="Teton Ridge Classic Contact Information" />
    <PageTitle 
      title="Contact Us"
    />
    <Container fluid>
      <Row className="my-4">
        <Col sm={6} className="mb-2">
          
         <h2>Send Us A Message</h2>
        <ContactForm />
        </Col>
        <Col sm={6}>
        <h2>Contact Information</h2>
        <Row>
          <Col sm={8}className="mb-2">
            
        <p style={{fontSize: '29px'}}><strong>Dave Bergart</strong> – Race Director<br />
</p>            
            </Col>
            <Col sm={4}>
              <Img fluid={data.danPhoto.childImageSharp.fluid} alt="Race director Dan Streubel" className="rounded" />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
    <Container fluid>
    <hr />
        <Row>
              <Partners />
            </Row>
            <hr />
            <Row>
              <Sponsors />
            </Row>
            </Container>
  </Layout>
)
export const query = graphql`
  query {
    danPhoto: file(relativePath: { eq: "photo_Dan.jpg" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }`

export default Contact
